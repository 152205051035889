<template>
  <div class="mt-4  px-5">
    <v-card>
      <v-card-title>
        Passwort ändern
      </v-card-title>
      <v-card-text>

  <v-form
      ref="form"
      v-model="valid">
  <v-container>
    <v-row>
      <v-text-field
          v-model="password_old"
          name="input-10-1"
          hint=""
          label="Altes Passwort"
          :rules="[rules.required, rules.min]"
          :type="showPW_OLD ? 'text' : 'password'"
          @click:append="showPW_OLD = !showPW_OLD"
          :append-icon="showPW_OLD ? 'mdi-eye' : 'mdi-eye-off'"
          required
          outlined
      ></v-text-field>
    </v-row>

    <v-row>
        <v-text-field
            v-model="password"
            name="input-10-1"
            hint=""
            label="Passwort"
            :rules="[rules.required, rules.min]"
            :type="showPW1 ? 'text' : 'password'"
            @click:append="showPW1 = !showPW1"
            :append-icon="showPW1 ? 'mdi-eye' : 'mdi-eye-off'"
            required
            outlined
        ></v-text-field>
      </v-row>
    <v-row>
        <v-text-field
            v-model="password1"
            name="input-10-1"
            hint=""
            label="Passwort wiederholen"
            :rules="[rules.required, passwordConfirmationRule]"
            :type="showPW2 ? 'text' : 'password'"
            @click:append="showPW2 = !showPW2"
            :append-icon="showPW2 ? 'mdi-eye' : 'mdi-eye-off'"
            required
            outlined
        ></v-text-field>
    </v-row>
    <v-row>
    <v-btn
        color="primary"
        @click="save"
    >
      passwort ändern
    </v-btn>
    </v-row>

  </v-container>
  </v-form>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
export default {
  name: 'changePassword',
  data() {
    return {
      valid: false,
      password_old: "",
      password: "",
      password1: "",
      showPW_OLD: false,
      showPW1: false,
      showPW2: false,
      rules: {
        required: value => !!value || 'Wird benötigt',
        min: v => v.length >= 8 || 'Passwort zu kurz',
      },
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.password1) || 'Die Passwörter stimmen nicht überein!'
    }
  },
  methods: {
    async save() {
      var Data = {
        current_password:this.password_old,
        new_password:this.password,
        new_password_confirmation:this.password1
      }
      await this.$store.dispatch('ClubAuth/setNewPassword', Data)
       .then(
           // this.$refs.form.reset()
       )
    },

  },
}
</script>

<style scoped>

</style>